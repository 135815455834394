<template>
  <div class="sj">
    <div class="sj_top">
      <van-image width="100%" height="100%" :src="require('@/assets/imgs/topimg.png')" />
    </div>
    <div class="sj_min">
      <div class="sj_min_div">
        <van-notice-bar scrollable left-icon="volume-o" text="各类视频会员充值优惠中，海量会员五折起。" />
        <van-image width="100%" height="100%" :src="require('@/assets/imgs/title2 (2).png')" />
        <div class="goodslist">
          <div class="goodslist_item" v-for="(value,index) in goodList" :key="value.id" icon="icon-shipin" @click="clickitem(value,index)">
            <div class="goodslist_item_img">
              <van-image width="3.5em" height="3.5em" :src="value.showImage" />
            </div>
            <div class="goods-item-name">
              <p>{{value.name}}</p>
            </div>
          </div>
        </div>
        <div class="goodsSku" ref="warp" v-for="(item,index) in goodSkuList" :key="index">
          <van-image width="100%" height="100%" :src="item.thumbnail" />
          <p class="goodsSku_goodsName">{{item.goodsTitle}}</p>
          <van-grid :column-num="3" :gutter="7" center>
            <van-grid-item v-for="(value,index) in item.goodsSkus" :key="index" @click="clickGrid($event,index,value)">
              <div class="goodsSku_skuTitle">{{JSON.parse(value.spData)|spDataFilter}}</div>
              <span class="goodsSku_faceValue">官网价￥{{value.faceValue}}</span>
              <span class="goodsSku_discount">立减￥{{(value.faceValue-value.retailPrice).toFixed(2)}}</span>
              <span class="goodsSku_retailPrice">实际支付￥{{(value.retailPrice).toFixed(2)}}</span>
              <van-button round type="warning" size="mini">立即充值</van-button>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
    </div>
    <div class="sj_foot">
      <div>
        <van-image width="100%" height="100%" :src="require('@/assets/imgs/tip_05.png')" />
      </div>
      <div class="sj_foot_tip">
        <p>1.充值账号填写手机号，腾讯视频手机号和QQ号均支持。 </p>
        <p>2.填写充值账号后直接充值到您的账户内，充值时客服不会向你寻要验证码。</p>
        <p>3.虚拟商品不支持退换货，下单前请仔细核对充值账号。</p>
        <p>4.若账号有问题无法充值成功，我们会在24小时内联系您更换账号或退款，请无需担心。</p>
        <p>5.正常充值到账时间一般为1-10分钟内，最长不超过24小时，若长时间未到账，请联系客服。</p>
        <p>6.客服电话：028-83546764，工作日客服时间9:00-18:00点。</p>
      </div>
    </div>
    <van-dialog v-model="show" :title="title" show-cancel-button :before-close='beforeClose' confirm-button-text="确认购买" cancel-button-text="取消购买">
      <van-form validate-first ref="myform">
        <van-field v-model="account" label="充值账号" name="account" placeholder="请输入充值账号" :rules="rules" />
        <div class="tip_text">
          <div v-html="this.instructions"></div>
        </div>
      </van-form>
    </van-dialog>
    <van-dialog v-model="show1" show-cancel-button confirm-button-text="确认无误" cancel-button-text="更改号码" @confirm="confirmed">
      <div style="
        display: flex;flex-direction: column;align-items: center; margin:2.2em 0; line-height:4em;">
        <p>您当前的充值号码为：</p>
        <p style="font-size:1.6em; font-weight:600">{{account}}</p>
      </div>
    </van-dialog>
    <div class="sj_order" @click="oder">
      <van-icon name="orders-o" />
      <p>订单</p>
    </div>
  </div>
</template>
<script>
import { api_goodSKU, api_goodlist, api_gooddetails, api_authoirze, api_userinfo, api_jsSdkconfig, api_micropay,api_insert_visitors } from "@/api/api.js"
import wx from 'weixin-js-sdk'
import { getQueryVariable } from "@/util/utils.js"
export default {
  data () {
    return {
      supplierId: "",
      goodList: [],
      goodsId: "",
      goodSkuList: [],
      show: false,
      account: "",
      title: "",
      num: 0,
      show1: false,
      instructions: "",
      skuId: '',
      placeholder: "",
      state: "",
      rules: [
        {
          name: "account",
          required: true,
          trigger: "onBlur/onChange",
        },
        {
          validator: this.check, trigger: "change"
        },
      ]
    }
  },
  created () {
    this.supplierId = this.$route.query.supplierId
    this.getGoodSku()
    this.getGoodList()
    this.getjsSDK()
    this.getToken()
    this.visitors()
  },
  methods: {
    check (value) {
      var length = value.length;
      if (this.goodsId === "ab797663bd85c709af9208da3fef1440") {
        if (length == 11 && !/^[1][3-9][\d]{9}/.test(value)) {
          this.$toast.fail('请输入正确格式的手机号码');
          return false;
        } else if (!/^[1-9][0-9]{4,10}$/.test(value)) {
          this.$toast.fail('请输入正确格式的手机号码或QQ号码');
          return false;
        }
      } else {
        if (length == 11 && /^[1][3-9][\d]{9}/.test(value)) {
          return true;
        } else {
          this.$toast.fail('请输入正确格式的手机号码');
          return false
        }
      }
    },
    getjsSDK () {
      api_jsSdkconfig().then(res => {
        if (res.data.code == 0) {
          wx.config({
            debug: false,
            appId: res.data.data.appId,
            timestamp: res.data.data.timestamp,
            nonceStr: res.data.data.noncestr,
            signature: res.data.data.signature,
            jsApiList: ['chooseWXPay']
          })
        }
      })
    },
    getCode () {
      const params = {
        redirect_url: window.location.href
      }
      api_authoirze(params).then(res => {
        if (res.data.code == 0) {
          window.location.replace(res.data.data)
        } else {
          console.log(res.data.message)
        }
      }).catch(err => {
        console.log(err)

      })
    },
    getToken () {
      const code = getQueryVariable("code");
      if (code) {
        this.Cook.set('code', code)
        const params = {
          code: code,
        }
        api_userinfo(params).then(res => {
          if (res.data.code == 0) {
            sessionStorage.setItem('accessToken', 'yh' + ' ' + res.data.data.accessToken)
            this.state = window.localStorage.getItem('state',)
            if (this.state) {
              this.goodsId = window.localStorage.getItem('goodsId')
              this.skuId = window.localStorage.getItem('skuId')
              this.account = window.localStorage.getItem('account')
              this.title = window.localStorage.getItem('title')
              this.supplierId = window.localStorage.getItem('supplierId')
              this.getGooddeTails()
              this.show = this.state
            }
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    getGoodList () {
      const params = {
        supplierId: this.supplierId
      }
      api_goodlist(params).then(res => {
        if (res.data.code == 0) {
          res.data.data.forEach(item => {
            this.goodList = item.goods
          });
        }
      })
    },
    getGooddeTails () {
      api_gooddetails(this.goodsId, this.supplierId).then(res => {
        if (res.data.code == 0) {
          let message = res.data.data.instructions; //data是你从json数组里面拿到的字符串
          this.instructions = message.replace(/\n/g, "<br/>");
        } else {
          console.log(res.data.message)
        }
      })
    },
    getGoodSku () {
      const params = {
        supplierId: this.supplierId
      }
      api_goodSKU(params).then(res => {
        if (res.data.code == 0) {
          this.goodSkuList = res.data.data
        }
      })
    },
    clickGrid ($event, inedex, value) {
      this.show = true
      window.localStorage.setItem('goodsId', value.goodsId)
      window.localStorage.setItem('skuId', value.id)
      window.localStorage.setItem('state', this.show)
      window.localStorage.setItem('supplierId', this.supplierId)
      window.localStorage.setItem('title', value.fullName)
      this.title = value.fullName
      this.goodsId = value.goodsId
      this.skuId = value.id
      this.getGooddeTails()
    },
    async beforeClose (e, done) {
      if (e === 'confirm') {
        this.$refs.myform.validate("account").then(() => {
          localStorage.setItem('account', this.account)
          localStorage.setItem('state', this.show)
          console.log(this.show)
          const token = sessionStorage.getItem('accessToken')
          if (token) {
            this.show1 = true
          } else {
            this.getCode()
          }
          return done(false)
        }).catch(() => {
          return done(false)
        })
      } if (e === 'cancel') {
        this.placeholder = ''
        this.$refs["myform"].resetValidation("account");
        this.account = ''
        return done()
      }
    },
    confirmed () {
      this.$refs["myform"].resetValidation("account");
      this.show = false;
      const params = {
        account: this.account,
        sendEmail: null,
        remark: null,
        goodsId: this.goodsId,
        skuId: this.skuId,
        supplierId: this.supplierId,
        tradeType: 4
      }
      api_micropay(params).then(res => {
        if (res.data.returnMsg == 'OK') {
          const pay_params = JSON.parse(res.data.orderInfo)
          if (typeof WeixinJSBridge === "undefined") {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
            }
          } else {
            this.onBridgeReady(pay_params);
            this.$toast.success("充值成功");
            this.account = ''
            this.$refs["myform"].resetValidation("account");
          }
        } else {
          this.$toast.fail('支付失败' + res.data.message);
        }
      }).catch((err) => {
        alert(err);
      })
    },
    onBridgeReady (params) {
      wx.chooseWXPay({
        timestamp: params.timeStamp,
        nonceStr: params.nonceStr,
        package: params.package,
        signType: params.signType,
        paySign: params.paySign,
        success: function (res) {
          alert('res', res.data.msg);
        },
        fail: function (res) {
          console.log('res', res)
        },
        complete: function (res) {
          console.log(res, 'complete')
        }
      });
    },

    clickitem (item) {
      var oDiv = document.getElementsByClassName('goodsSku')
      for (const key in this.goodSkuList) {
        if (this.goodSkuList[key].goodsTitle == item.title) {
          oDiv[key].scrollIntoView()
        }
      }
    },
    oder () {
      const token = sessionStorage.getItem('accessToken')
      if (token) {
        this.$router.push({
          path: '/order',
          query: {
            supplierId: this.supplierId,
          }
        })
      } else {
        this.$router.push({
          path: '/queryorder',
          query: {
            supplierId: this.supplierId,
          }
        })
      }
    },
    visitors(){
      const params={
        sourceUrl:window.location.href,
        supplierId:this.supplierId
      }
      api_insert_visitors(params).then(res=>{
        if(res.data.code==0){
          console.log(res.data)
        }
      })
    }
  },
  filters: {
    spDataFilter (num) {
      let str = ""
      for (const key in num) {
        str += num[key].value
      }
      return str
    }
  }
}
</script>
<style lang="scss" scoped>
.sj {
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eef3f7;
  .sj_top {
    width: 100%;
  }
  .sj_min {
    width: 100%;
    .sj_min_div {
      padding: 0 1em;
      .van-notice-bar {
        background: #fff;
        border-radius: 2em;
        height: 2em;
        font-size: 1.4em;
      }
      .van-image {
        padding: 0;
      }
      .goodslist {
        width: 100%;
        margin-top: 0.5em;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: scroll;
        .goodslist_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 0.9em;
          .goodslist_item_img {
            width: 6.6em;
            height: 6.6em;
            display: flex;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
            background: #fff;
          }
          .goods-item-name {
            width: 100%;
            text-align: center;
            margin-top: 0.6em;
            font-size: 1.1em;
          }
        }
      }
      .goodsSku {
        margin-top: 1em;
        background: #f7f4f1;
        padding-bottom: 2em;
        border-radius: 1em;
        margin-bottom: 2em;
        box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
        .goodsSku_goodsName {
          font-size: 1.8em;
          font-weight: 600;
          text-align: center;
          margin: 0.4em 0;
        }
        .van-grid-item {
          ::v-deep .van-grid-item__content {
            padding: 0;
            box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
            border-radius: 1em;
          }
          .goodsSku_skuTitle {
            color: #5d8fd1;
            font-size: 1.4em;
            margin: 0.5em 0 0.3em 0;
            font-weight: 600;
          }
          .goodsSku_faceValue {
            color: #a7a7a7;
            text-decoration: line-through;
          }
          .goodsSku_discount {
            color: #f54848;
            font-weight: 600;
            margin: 0.3em 0;
          }
          .goodsSku_retailPrice {
            font-size: 1.2em;
            font-weight: 600;
            margin-bottom: 1.1em;
            background: linear-gradient(to bottom, #f99815, #f84a04);
            background-clip: text;
            color: transparent;
          }
          .van-button--mini {
            height: 2.5em;
            padding: 0 2em;
            font-size: 0.9em;
            background: linear-gradient(to bottom, #f99815, #f84a04);
            margin-bottom: 1.5em;
          }
        }
      }
    }
  }
  .sj_foot {
    padding: 1em;
    .sj_foot_tip {
      background: #f7f4f1;
      padding: 1em 2em;
      border-radius: 1em;
      box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
      p {
        color: #808080;
        font-weight: 500;
        line-height: 2em;
        font-size: 1.2em;
      }
    }
  }
  .van-dialog {
    .van-form {
      margin: 1em 0;
      .van-cell {
        line-height: 2em;
        align-items: center;
        ::v-deep .van-field__label {
          width: 4.5em;
        }
      }
      ::v-deep .van-cell__value {
        .van-field__body {
          .van-field__control {
            border: 0.1em solid #ccc;
            border-radius: 2em;
            padding: 0 1em;
            background: #fff;
          }
        }
      }
    }
    .tip_text {
      padding: 1em;
      font-size: 0.8em;
      font-weight: 400;
      color: #4d4d4d;
      line-height: 2em;
    }
  }
  .sj_order {
    width: 5.5em;
    height: 5.5em;
    position: fixed;
    display: flex;
    border-radius: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: red;
    box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
    background: #fff;
    right: 5%;
    top: 70%;
    border-radius: 50%;
    .van-icon {
      font-size: 2.5em;
    }
  }
}
</style>
